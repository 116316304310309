<template>
  <div class="replying">
    <!-- <p class="myclass-title">
      <span class="title-text">我的回帖</span>
      <span v-show="count>0">（{{ count }}）</span>
      <span class="title-border"></span>
    </p> -->
    <headers ref="headers" :headname="headName" :count="count"></headers>

    <!-- 有数据展示 -->
    <div v-show="count>0" class="reply_list">
      <div v-for="(item,index) in list" :key="index" class="zhul_zy_list" style="display: flex;">
        <div class="info">
          <div class="zhul_zy_rtop">
            <p class="zhul_zy_rtit" @click="thread_detail(item)">
              {{ item.thread_title }}
            </p>
          </div>

          <p class="content" v-html="item.message">
            {{ item.message }}
          </p>

          <p class="zhul_lb_reply">
            最后发帖：
            <span class="last_user" @click="userArchive(item.uid)">{{ item.username }}</span>
            <!-- <span class="reply_name">{{ item.create_time|formatDate }}</span> -->
            <span class="reply_name">{{ item.createDay }}</span>
          </p>
        </div>
      </div>

      <!-- 分页 -->
      <div class="feny">
        <el-pagination
          v-show="count>10"
          class="page"
          background
          layout="prev, pager, next"
          :page-size="params.limit"
          :total="count"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>

    <!-- 无数据展示 -->
    <noData v-show="count===0" ref="noData" :msg="msg"></noData>
    <!-- <div v-show="count===0" class="noDataShow">
      <img src="@/assets/image/kong.png" alt="">
      <p>您还没有回帖哦~</p>
    </div> -->
  </div>
</template>

<script>
import { myreplypost } from '@/api/center.js'
// import tool from '@/utils/tools.js'
import headers from '@/components/topTitle/index.vue'
import noData from '@/components/noDataBox/index.vue'
export default {
  name: '',
  components: { headers, noData },
  // filters: {
  //   formatDate(time) {
  //     if (time !== null && time !== '') {
  //       // time = time * 1000
  //       // const date = new Date(time)
  //       // return tool.formatDate(date, 'yyyy-MM-dd')
  //       const dt = new Date(time)
  //       const y = dt.getFullYear()
  //       const m = (dt.getMonth() + 1 + '').padStart(2, '0')
  //       const d = (dt.getDate() + '').padStart(2, '0')

  //       const hh = (dt.getHours() + '').padStart(2, '0')
  //       const mm = (dt.getMinutes() + '').padStart(2, '0')
  //       const ss = (dt.getSeconds() + '').padStart(2, '0')

  //       return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
  //     } else {
  //       return ''
  //     }
  //   }
  // },

  data() {
    return {
      headName: '我的回帖',
      msg: '您还没有回帖哦~',
      count: undefined,
      list: [],
      params: {
        page: 1,
        limit: 10
      }
    }
  },

  computed: {},

  created() {
    this.getlist()
  },
  mounted() {
  },
  methods: {
    getlist() {
      myreplypost(this.params).then(res => {
        this.list = res.result.list
        this.count = res.result.count
        console.log(res)
      }).catch(err => {
        console.log(err)
      })
    },
    // 分页
    handleCurrentChange(val) {
      // 当前点击页数
      this.params.page = val
      this.getlist()
    },
    // 跳转帖子详情
    thread_detail(item) {
      window.open(item.detail_url, '_blank')
    },
    userArchive(uid) {
      const routeUrl = this.$router.resolve({
        path: '/archive',
        query: { u: uid }
      })
      window.open(routeUrl.href, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.replying {
	width: 965px;
  padding: 0 0 20px 0;
	min-height: 900px;
  position: relative;
  font-family: 'PingFangSC-Regular';
}
.myclass-title {
	padding: 26px 60px 12px 10px;
	width: 830px;
	font-size: 19px;
	position: relative;
	text-align: left;
}
.title-border {
	position: absolute;
	left: 0;
	bottom: 16px;
	width: 4px;
	height: 20px;
	z-index: 2;
	background: #f04e4f;
}
.reply_list {
  margin-left: 30px;
  padding: 0 30px 0 0;
}
.zhul_zy_list {
	float: left;
	width: 100%;
	border-bottom: 1px solid #DDDDDD;
	padding: 16px 0 16px 0;
}
.info {
	float: left;
	width: 100%;
	background-color: #fff;
}
.zhul_zy_rtop {
	float: left;
	width: 100%;
	line-height: 28px;
}
.zhul_zy_rtit {
	float: left;
	font-size: 18px;
	max-width: 500px;
	color: #333333;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	cursor: pointer;
}
.content {
	float: left;
	width: 100%;
	line-height: 20px;
	margin-top: 5px;
	font-size: 14px;
	text-align: left;
	color: #666;
	word-break: break-all;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}
.zhul_lb_reply {
	float: right;
	color: #999;
	padding-top: 5px;
	.last_user {
		cursor: pointer;
	}
}
.zhul_lb_reply, .reply_name {
  margin-left: 15px;
}
.noDataShow {
	position: absolute;
	width: 180px;
	height: 180px;
	top: 50%;
	left: 50%;
	margin-top: -90px;
}
.noDataShow img {
	display: block;
	margin: auto;
}
.noDataShow p {
	text-align: center;
	font-size: 14px;
	color: #999999;
	margin-top: 30px;
}
.feny {
	display: inline-block;
	margin-top: 20px;
}
</style>
